const Cennik = () => {
    return (
        <div className="cennik-container">
            <table className="pricelist-table">
                <thead>
                    <tr>
                        <td>Myjnia</td>
                        <td>Mały</td>
                        <td>Standard</td>
                        <td>SUV</td>
                        <td>Duży SUV/VAN</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Mycie zewnętrzne</td><td>100 zł</td><td>110 zł</td><td>120 zł</td><td>140 zł</td></tr>
                    <tr><td>Pielęgnacja wnętrza</td><td>100 zł</td><td>110 zł</td><td>120 zł</td><td>140 zł</td></tr>
                    <tr><td>Komplet</td><td>180 zł</td><td>200 zł</td><td>220 zł</td><td>240 zł</td></tr>
                    {/* pakiety */}
                                                  <tr><td>Komplet z płynnym woskiem</td><td>200 zł</td><td>230 zł</td><td>250 zł</td><td>290 zł</td></tr>
                                               <tr><td>Komplet z powłoką polimerową</td><td>240 zł</td><td>260 zł</td><td>290 zł</td><td>320 zł</td></tr>
                                                  <tr><td>Komplet z twardym woskiem</td><td>350 zł</td><td>370 zł</td><td>390 zł</td><td>420 zł</td></tr>
                                                <tr><td>Komplet z powłoką teflonową</td><td>390 zł</td><td>410 zł</td><td>430 zł</td><td>460 zł</td></tr>

                                                    <tr><td>Pakiet "HYDROFOBOWOŚĆ"</td><td>500 zł</td><td>520 zł</td><td>560 zł</td><td>590 zł</td></tr>
                                                    <tr><td>Pakiet "ZABEZPIECZENIE"</td><td>540 zł</td><td>560 zł</td><td>580 zł</td><td>610 zł</td></tr>
                                                            <tr><td>Pakiet "POŁYSK"</td><td>500 zł</td><td>520 zł</td><td>560 zł</td><td>590 zł</td></tr>
        <tr><td>Pakiet pielęgnacji wnętrza z praniem foteli lub czyszczeniem foteli skórzanych</td><td>od 380 zł</td><td>od 390 zł</td><td>od 400 zł</td><td>od 430 zł</td></tr>
                                    <tr><td>Pakiet kompleksowej pielęgnacji wnętrza</td><td>od 630 zł</td><td>od 680 zł</td><td>od 830 zł</td><td>od 870 zł</td></tr>
                                    <tr><td>Pakiet kompleksowej pielęgnacji samochodu</td><td>od 1100 zł</td><td>od 1300 zł</td><td>od 1600 zł</td><td>od 2000 zł</td></tr>
                </tbody>
                </table>
                <table className="pricelist-table pricelist-table2">
                <thead>
                    <tr>
                        <td colSpan={2}>Wymiana opon</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Felgi stalowe 12''</td><td>120 zł</td></tr>
                    <tr><td>Felgi stalowe 13''</td><td>140 zł</td></tr>
                    <tr><td>Felgi stalowe 14''</td><td>150 zł</td></tr>
                    <tr><td>Felgi stalowe 15''</td><td>170 zł</td></tr>
                    <tr><td>Felgi stalowe 16''</td><td>180 zł</td></tr>
                    <tr><td>Felgi stalowe 17''</td><td>200 zł</td></tr>
                    <tr><td>Felgi stalowe 18''</td><td>200 zł</td></tr>
                    <tr><td>Felgi stalowe 19'' i większe</td><td>220 zł</td></tr>
                    <tr><td>Felgi stalowe dostawcze</td><td>220 zł</td></tr>
                    <tr><td>Felgi stalowe 4X4</td><td>+20 zł od koła</td></tr>
                    <tr><td>Felgi stalowe RUN FLAT</td><td>+20 zł od koła</td></tr>
                    <tr><td>Felgi aluminiowe 12''</td><td>140 zł</td></tr>
                    <tr><td>Felgi aluminiowe 13''</td><td>150 zł</td></tr>
                    <tr><td>Felgi aluminiowe 14''</td><td>160 zł</td></tr>
                    <tr><td>Felgi aluminiowe 15''</td><td>175 zł</td></tr>
                    <tr><td>Felgi aluminiowe 16''</td><td>195 zł</td></tr>
                    <tr><td>Felgi aluminiowe 17''</td><td>215 zł</td></tr>
                    <tr><td>Felgi aluminiowe 18''</td><td>240 zł</td></tr>
                    <tr><td>Felgi aluminiowe 19''</td><td>260 zł</td></tr>
                    <tr><td>Felgi aluminiowe 20''</td><td>280 zł</td></tr>
                    <tr><td>Felgi aluminiowe 21''</td><td>290 zł</td></tr>
                    <tr><td>Felgi aluminiowe 22'' i większe</td><td>320 zł</td></tr>
                    <tr><td>Felgi aluminiowe dostawcze</td><td>od 240 zł</td></tr>
                    <tr><td>Felgi aluminiowe 4X4</td><td>+20 zł od koła</td></tr>
                    <tr><td>Felgi aluminiowe RUN FLAT</td><td>+20 zł od koła</td></tr>
                    <tr><td>Wymiana całych kół</td><td>od 150 zł</td></tr>
                    <tr><td>Wyważanie</td><td>od 150 zł za komplet</td></tr>
                    <tr><td>Utylizacja opony</td><td>30 zł</td></tr>
                    <tr><td>Hotel dla opon</td><td>99 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Układ klimatyzacji: czynnik r134a</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>podłączenie maszyny TEXA</td><td>150 zł</td></tr>
                    <tr><td>plus opłata za czynnik (100g)</td><td>50 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Układ klimatyzacji: czynnik R1234</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>podłączenie maszyny TEXA</td><td>150 zł</td></tr>
                    <tr><td>plus opłata za czynnik (100g)</td><td>100 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Serwis okresowy samochodu</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>wymiana oleju silnikowego z filtrem oleju</td><td>od 100 zł</td></tr>
                    <tr><td>wymiana filtra paliwa</td><td>od 60 zł</td></tr>
                    <tr><td>wymiana filtra powietrza</td><td>od 20 zł</td></tr>
                    <tr><td>wymiana filtra kabinowego</td><td>od 60 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Geometria w technologii 3D</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>przednia oś</td><td>150 zł</td></tr>
                    <tr><td>przednia i tylna oś</td><td>200 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Układ hamulcowy samochodu</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>klocki przednia oś</td><td>od 100 zł</td></tr>
                    <tr><td>klocki tylnia oś</td><td>od 100 zł</td></tr>
                    <tr><td>klocki tylnia oś z cofaniem tłoczków elektrycznych</td><td>od 150 zł</td></tr>
                    <tr><td>tarcze hamulcowe z klockami przednia oś</td><td>od 120 zł</td></tr>
                    <tr><td>tarcze hamulcowe z klockami tylnia oś</td><td>od 120 zł</td></tr>
                    <tr><td>tarcze hamulcowe z klockami tylnia oś z cofaniem tłoczków</td><td>od 170 zł</td></tr>
                    <tr><td>bębny hamulcowe tylnia oś</td><td>od 150 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Układ zawieszenia samochodu - wymiana</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>amortyzatora</td><td>od 120 zł</td></tr>
                    <tr><td>sprężyny</td><td>od 120 zł</td></tr>
                    <tr><td>amortyzatora ze sprężyną</td><td>od 130 zł</td></tr>
                    <tr><td>końcówki drążka kierowniczego</td><td>od 100 zł</td></tr>
                    <tr><td>sworznia</td><td>od 70 zł</td></tr>
                    <tr><td>łącznika stabilizatora</td><td>od 50 zł</td></tr>
                    <tr><td>gumy łącznika stabilizatora</td><td>od 70 zł</td></tr>
                    <tr><td>osłony przegubu i przegubu</td><td>od 150 zł</td></tr>
                    <tr><td>łożyska koła z piastą</td><td>od 150 zł</td></tr>
                    <tr><td>tuleji wahacza</td><td>od 150 zł</td></tr>
                    <tr><td>wahacza</td><td>od 150 zł</td></tr>
                </tbody>

                <thead>
                    <tr>
                        <td colSpan={2}>Pozostałe</td>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Diagnostyka komputerowa</td><td>80 zł</td></tr>
                    <tr><td>Wymiana płynu chłodniczego</td><td>od 100 zł</td></tr>
                    <tr><td>Wymiana płynu hamulcowego</td><td>od 100 zł</td></tr>
                    <tr><td>Wymiana chłodnicy</td><td>od 150 zł</td></tr>
                    <tr><td>Wymiana rozrusznika</td><td>od 150 zł</td></tr>
                    <tr><td>Wymiana alternatora</td><td>od 150 zł</td></tr>
                    <tr><td>Wymiana świecy zapłonowej</td><td>od 20 zł</td></tr>
                    <tr><td>Wymiana świecy żarowej</td><td>od 50 zł</td></tr>
                    <tr><td>Wymiana żarówki</td><td>od 10 zł</td></tr>
                </tbody>
            </table>
            <p className="pricelist-disclaimer">Powyższy cennik ma charakter poglądowy i nie stanowi oferty handlowej w rozumieniu art. 66 par. 1 Kodeksu Cywilnego.<br></br>Dokładna cena usługi zostanie wskazana na miejscu po inspekcji pojazdu.</p>
        </div>
    )
}

export default Cennik;