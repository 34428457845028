import {React, useEffect, useState} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import InnerModal from "./InnerModal";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import promo1 from "../img/promo1.png";
import promo2 from "../img/promo2.png";
import promo3 from "../img/promo3.png";
import promo4 from "../img/promocje_garage_LP.jpg";
import promo5 from "../img/przeglad_LP_promocje.jpg";
import promo6 from "../img/geometria_LP_promocje.jpg";
import promo7 from "../img/swiatla_LP_promocje.jpg";
import promo8 from "../img/promo_zabezpieczenie.jpg";
import promo9 from "../img/promo_kupujesz_www.jpg";
import promo10 from "../img/promo_sprzedajesz_www.jpg";
import promo11 from "../img/ferie_big.jpg";
import promo99 from "../img/123.jpg";
import promoferie from "../img/ferie25.jpg";
import promo999 from "../img/promo999.jpg";
import promo9999 from "../img/gpa.jpg";


const Promocje = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      }, []);
      const onCloseModal = () => setOpen(false);
      const [open, setOpen] = useState(false);
      const [services, setServices] = useState();
  

      useEffect(()=>{
        fetch('https://test.cms.soft99.eu/garage-services')
            .then(resp => resp.json())
            .then((data) => {
                setServices(data);
            })
    },[])

    return(
        <div className="promopageback">
            <header>
                <Navbar services={services}/>
            </header>
            <Modal open={open} onClose={onCloseModal} center>
                <InnerModal />
            </Modal>
            <section>
                <div className="container promocont">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="promotitle">Promocje</p>
                        </div>
                        <div className="col-lg-12">
                            <div className="promolist">

                                <div className="promo-con">
                                    <a href="https://soft99garage.pl/kosmetyka/pakiet-ferie">
                                        <img className="promoimg" src={promoferie}/>
                                    </a>
                                    <p className="promodesc">
                                    W Soft99 Garage trzymamy rękę na pulsie. Wielkopolskie ferie startują 20 stycznia i trwają do 2 lutego. A u nas możesz całościowo przygotować samochód do bezpiecznej i wygodnej podróży!

                                    </p>
                                </div>

                                <div className="promo-con">
                                    <a href="https://www.soft99garage.pl/serwis/felgi-i-opony">
                                        <img className="promoimg" src={promo9999}/>
                                    </a>
                                    <p className="promodesc">
                                    Ty umawiasz się na zmianę opon lub kół, a my dorzucamy Ci zupełnie ZA DARMO ręczne mycie karoserii Twojego auta. Sezonowa wymiana opon, całych kół, wyważanie, a może awaryjna sytuacja? U nas znajdziesz fachową pomoc we wszystkim, co związane z felgami oraz oponami w Twoim pojeździe.
                                    </p>
                                </div>

                                <div className="promo-con">
                                    <a href="https://soft99garage.pl/kosmetyka/protection-time">
                                        <img className="promoimg" src={promo999}/>
                                    </a>
                                    <p className="promodesc">
                                    Rusza największa akcja promocyjna w Soft99 Garage! Darmowy przegląd do każdego Pakietu Protection Time! Zabezpiecz swój samochód przed trudnymi warunkami jesienno-zimowymi, wybierając 12-miesięczne zabezpieczenie woskiem Fusso Coat na lakier oraz powłoką Glaco na szyby. Dzięki tym niezawodnym produktom, Twój pojazd będzie odporny na brud, wodę, sól i inne szkodliwe czynniki atmosferyczne nawet przez cały rok! A co najlepsze, przegląd techniczny otrzymasz od nas zupełnie za darmo!
                                    </p>
                                </div>

                                <div className="promo-con">
                                    <a href="https://www.soft99garage.pl/kosmetyka/myjnia">
                                        <img className="promoimg" src={promo99}/>
                                    </a>
                                    <p className="promodesc">
                                        Jazda w pełnym blasku to prawdziwa przyjemność, ale jazda w pełnym słońcu z niesprawną lub mało wydajną klimatyzacją skutecznie odbierze całą radość podróży. Mamy jednak coś, co zapewni Twojemu autu blask, a Tobie chłód we wnętrzu. Wszystko w ramach jednej wizyty, w rekordowo dobrej cenie! Sprawa jest prosta, umów się na usługę mycia auta, a serwis klimatyzacji wykonamy za jedyne 99 PLN*!
                                    </p>
                                </div>

                                <div className="promo-con">
                                    <a href="/program-lojalnosciowy">
                                        <img className="promoimg" src={promo4}/>
                                    </a>
                                    <p className="promodesc">
                                    Potrzebujesz fajnych zdjęć swojego auta w niestandardowym miejscu? Nieważne, czy sprzedajesz auto, chcesz uwiecznić swój wyjątkowy projekt, czy po prostu chcesz zatrzymać czas na fotkach. Wyróżnij się i zrób to w Soft99 Garage zupełnie ZA DARMO! Oddajemy do Twojej dyspozycji unikalną strefę fotograficzną inspirowaną prawdziwymi tunelami lakierniczymi z fabryk samochodowych. Oświetleniowa konstrukcja LED doskonale podkreśla blask lakieru, detali oraz kształt nadwozia i sprawia, że każde auto wygląda doskonale. Do tego otoczenie kultowych aut, klimat studia detailingowego i masz gotową receptę na niepowtarzalne fotki!
                                    </p>
                                </div>
                                {/* <div className="promo-con">
                                    <a href="https://www.facebook.com/Soft99Garage" target="_blank">
                                        <img className="promoimg" src={promo11}/>
                                    </a>
                                    <p className="promodesc">
                                    Zbliżają się zimowe ferie, a my chcemy zadbać o Twoją bezpieczną podróż w czasie zimowego wypoczynku! Dlatego przygotowaliśmy specjalną ofertę, abyś mógł cieszyć się feriami z pełnym spokojem.
                                    <br /><br />Co oferujemy w ramach bezpiecznych ferii zimowych:
                                    <br /><br />1. Kompleksowa kontrola sprawności pojazdu, a w tym: kontrola oświetlenia, kontrola akumulatora, kontrola stanu opon, pomiar i korekta ciśnienia w oponach, kontrola poziomu oleju, kontrola płynu chłodzącego, kontrola płynu hamulcowego, kontrola sprawności układu hamulcowego, kontrola układu zawieszenia oraz układu kierowniczego;
                                    <br />2. Detailingowe mycie zewnętrzne samochodu;
                                    <br />3. Aplikacja powłoki hydrofobowej Glaco na szybę czołową, która poprawi bezpieczeństwo jazdy w trudnych zimowych warunkach
                                    <br /><br />Nie czekaj! Zarezerwuj swój termin już dziś i ciesz się bezpiecznymi feriami zimowymi z pełnym komfortem podróżowania. Pakiet FERIE to tylko 149 zł, zamiast 215 zł! Zniżka -30% to nasz sposób na zimową przygodę bez stresu!
                                    </p>
                                </div> */}

                                <div className="promo-con">
                                    <a href="/samochod/zakup">
                                        <img className="promoimg" src={promo9}/>
                                    </a>
                                    <p className="promodesc">
                                        Chcesz uniknąć nieprzyjemnych niespodzianek przy zakupie samochodu? Teraz to możliwe! Przyjedź do nas, a my sprawdzimy wybrany przez Ciebie samochód. Nie ryzykuj! Zaoszczędź czas i pieniądze, wybierając nasze profesjonalne pakiety kompleksowo sprawdzające samochód przed zakupem. To inwestycja w Twoje bezpieczeństwo i spokój umysłu. Teraz 20% taniej! 
                                    </p>
                                </div>

                                <div className="promo-con">
                                    <a href="/samochod/sprzedaz">
                                        <img className="promoimg" src={promo10}/>
                                    </a>
                                    <p className="promodesc">
                                        Chcesz, żeby Twój samochód robił imponujące pierwsze wrażenie? Nic prostszego! Skorzystaj z naszych pakietów, które kompleksowo przygotują Twój samochód do sprzedaży i zwiększą jego atrakcyjność na rynku. Nie trać czasu ani pieniędzy! Wybierz jeden z trzech naszych pakietów z rabatami do 50% i sprzedaj swój samochód z większym zyskiem i pewnością udanej transakcji. 
                                    </p>
                                </div>

                                {/* <div className="promo-con">
                                    <a href="/kosmetyka/myjnia">
                                        <img className="promoimg" src={promo8}/>
                                    </a>
                                    <p className="promodesc">
                                    Czy deszcz może zmyć blask? Wpadnij do nas, a udowodnimy Ci, że to tylko mit. Jest jeden warunek – auto po myciu musi być właściwie zabezpieczone, a my znamy się na tym jak nikt inny! Skorzystaj z naszej promocji w deszczowy dzień, odbierz 15% rabatu na PAKIET ZABEZPIECZENIE i przekonaj się, że można jeździć lśniącym autem w każdych warunkach. 
                                    </p>
                                </div> */}

                                <div className="promo-con">
                                    <a href="/program-lojalnosciowy">
                                        <img className="promoimg" src={promo5}/>
                                    </a>
                                    <p className="promodesc">
                                        Zbliża się termin badania technicznego? Nie musisz się specjalnie umawiać, ani czekać w kolejce i tracić czasu. Wpadnij do nas,  a my wykonamy przegląd od ręki. Cały proces nie zajmie więcej niż 20 minut,  a Ty odjedziesz z aktualną pieczątką w dowodzie rejestracyjnym w zaledwie kilka chwil.
                                    </p>
                                </div>

                                <div className="promo-con">
                                    <a href="/program-lojalnosciowy">
                                        <img className="promoimg" src={promo7}/>
                                    </a>
                                    <p className="promodesc">
                                        Właściwe ustawienie oświetlenia to nie tylko obowiązek, ale także istotny element Twojego bezpieczeństwa i komfortu podróży. Podczas okresowego badania technicznego sprawdzimy ustawienie oświetlenia i GRATIS skorygujemy ewentualne nieprawidłowości.
                                    </p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Promocje;